import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { debounce } from "lodash";

import NavigationBar from "../Navbar";
import HomePageHeader from "../molecule/homepage/HomePageHeader/HomePageHeader";
import HomePageFooter from "../molecule/homepage/HomePageFooter/HomePageFooter";

import HorizontalChart from "../General/HorizontalChart/HorizontalChart";

import GameInfo from "./components/GameInfo/GameInfo";
import GameOverview from "./components/GameOverview/GameOverview";
import GamePredictions from "./components/GamePredictions/GamePredictions";
import TopGamePredictions from "./components/TopGamePredictions/TopGamePredictions";
import GameVote from "./components/GameVote/GameVote";
import ErrorPage from "../../components/molecule/ErrorPage/ErrorPage";
import apiService from "../../services/apiService";

import "./GameDetail.css";

const GameDetail = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const isSmallLaptop = useMediaQuery({ maxWidth: 1280 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  const mainContainerRef = useRef(null);
  const additionalContainerRef = useRef(null);
  const overviewRef = useRef(null);
  const predictionsRef = useRef(null);
  const analyticsRef = useRef(null);
  const chatRef = useRef(null);
  const gameInfoMobileRef = useRef(null);
  const tabsRef = useRef(null);

  const currentPath = location.pathname;
  const pathParts = currentPath.split("/");
  const gameId = pathParts[pathParts.length - 1];

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [gameDetails, setGameDetails] = useState({});
  const [gameAPIDetails, setGameAPIDetails] = useState({});
  const [gameStatictic, setGameStatistic] = useState({});
  const [gameChartInfo, setGameChartInfo] = useState({});
  const [gameTopPredictions, setGameTopPredictions] = useState({});
  const [showNotification, setShowNotification] = useState(false);

  const [activeTab, setActiveTab] = useState("overview");

  // useEffect(() => {
  //   const sections = [
  //     { ref: overviewRef, tab: "overview" },
  //     { ref: chatRef, tab: "chat" },
  //     { ref: predictionsRef, tab: "predictions" },
  //     { ref: analyticsRef, tab: "analytics" },
  //   ];

  //   const options = {
  //     root: null,
  //     threshold: [0.1, 0.25, 0.5, 0.75, 1], // Точніші пороги для кращого відстеження
  //   };

  //   const observer = new IntersectionObserver(
  //     debounce((entries) => {
  //       let maxRatio = 0;
  //       let mostVisibleSection = activeTab;

  //       entries.forEach((entry) => {
  //         if (entry.intersectionRatio > maxRatio) {
  //           maxRatio = entry.intersectionRatio;
  //           mostVisibleSection = sections.find(
  //             (section) => section.ref.current === entry.target
  //           ).tab;
  //         }
  //       });

  //       setActiveTab(mostVisibleSection); // Встановлюємо найбільш видимий блок активним
  //     }, 100),
  //     options
  //   );

  //   // Додаємо спостерігачі для кожного блоку
  //   sections.forEach((section) => {
  //     if (section.ref.current) {
  //       observer.observe(section.ref.current);
  //     }
  //   });

  //   // Очищення після завершення
  //   return () => {
  //     sections.forEach((section) => {
  //       if (section.ref.current) {
  //         observer.unobserve(section.ref.current);
  //       }
  //     });
  //   };
  // }, [activeTab]);

  const getGameDetails = async () => {
    const res = await apiService.getGameDetails(gameId);

    if (res) {
      if (res.error) {
        setError(res.error);
      } else {
        setGameDetails(res.gameDetail);
        setGameAPIDetails(res.apiGameDetail);
      }
    }
  };

  const getGameStatistic = async () => {
    const res = await apiService.getGameStatistic(gameId);
    if (res) {
      setGameStatistic(res);
    }
  };

  const getGameChartInfo = async () => {
    const res = await apiService.getGameChartInfo(gameId);
    if (res) {
      setGameChartInfo(res);
    }
  };

  const getGameTopPredictions = async () => {
    const res = await apiService.getGameTopPredictions(gameId);
    if (res) {
      setGameTopPredictions(res);
    }
  };

  useEffect(() => {
    getGameDetails();
    getGameStatistic();
    getGameChartInfo();
    getGameTopPredictions();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("loading");
      getGameDetails();
      getGameStatistic();
      getGameChartInfo();
      getGameTopPredictions();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleActiveTab = (tabName) => {
    setActiveTab(tabName);

    const container = isTablet
      ? mainContainerRef.current
      : additionalContainerRef.current;

    const padding = isTablet ? 150 : 10;

    if (container) {
      const refs = {
        overview: overviewRef,
        chat: chatRef,
        predictions: predictionsRef,
        analytics: analyticsRef,
      };

      const selectedRef = refs[tabName];

      if (selectedRef && selectedRef.current) {
        const offset = selectedRef.current.offsetTop - container.offsetTop;

        container.scrollTo({
          top: offset - padding,
          behavior: "smooth",
        });
      }
    }
  };

  const handleScroll = () => {
    const tabsRect = tabsRef.current.getBoundingClientRect();
    const containerRect = mainContainerRef.current.getBoundingClientRect();

    const relativePosition = tabsRect.top - containerRect.top;

    if (relativePosition < 71) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }

    handleActiveTabByScroll(mainContainerRef)
  };

  const handleDesktopScroll = () => {
    handleActiveTabByScroll(additionalContainerRef)
  };

  const handleActiveTabByScroll = (mainContainer) => {
    const minRectTopPosition = 150;
    const containerRect = mainContainer.current.getBoundingClientRect();
    const sections = [
      { ref: overviewRef, name: "overview" },
      { ref: predictionsRef, name: "predictions" },
      { ref: analyticsRef, name: "analytics" },
      { ref: chatRef, name: "chat" },
    ];
  
    const activeSection = sections.find(({ ref, name }) => {
      const rect = ref.current.getBoundingClientRect();
      const position = rect.top - containerRect.top;
      const isActive = position < minRectTopPosition && position > -(rect.height - minRectTopPosition);
  
      return isActive;
    });
  
    if (activeSection) {
      setActiveTab(activeSection.name);
    }
  };

  return (
    <>
      {error ? (
        <>
          <Helmet>
            <title>Not Found</title>
          </Helmet>
          <ErrorPage />
        </>
      ) : (
        <div>
          <Helmet>
            <title>
              {localStorage.getItem("language") === "ua"
                ? gameDetails?.opp_1_name + " - " + gameDetails?.opp_2_name
                : gameDetails?.opp_1_name_en +
                  " - " +
                  gameDetails?.opp_2_name_en}
            </title>
            {/* <meta name="description" content={t("Leaderboard.metaDescription")} /> */}
          </Helmet>
          {localStorage.getItem("token") && localStorage.getItem("profile") ? (
            <NavigationBar countData={1} />
          ) : (
            <HomePageHeader />
          )}
          <div className="game-details__main-section">
            <div className="game-details__left-box">
              <GameInfo
                gameDetails={gameDetails}
                isLoading={isLoading}
                gameAPIDetails={gameAPIDetails}
              />
            </div>
            <div
              className="game-details__central-box"
              ref={mainContainerRef}
              onScroll={handleScroll}
            >
              {/* <div className="game-detail__top-info-wrapper"> */}
              {isTablet && (
                <GameInfo
                  gameDetails={gameDetails}
                  gameAPIDetails={gameAPIDetails}
                  isLoading={isLoading}
                  isScrolled={isScrolled}
                  gameInfoMobileRef={gameInfoMobileRef}
                />
              )}

              <div className="game-detail__tabs" ref={tabsRef}>
                <div
                  className={
                    activeTab === "overview"
                      ? "game-detail__tab active"
                      : "game-detail__tab"
                  }
                  onClick={() => handleActiveTab("overview")}
                >
                  {t("gameDetail.overView")}
                </div>
                {isSmallLaptop && (
                  <div
                    className={
                      activeTab === "chat"
                        ? "game-detail__tab active"
                        : "game-detail__tab"
                    }
                    onClick={() => handleActiveTab("chat")}
                  >
                    {t("gameDetail.chat")}
                  </div>
                )}
                <div
                  className={
                    activeTab === "predictions"
                      ? "game-detail__tab active"
                      : "game-detail__tab"
                  }
                  onClick={() => handleActiveTab("predictions")}
                >
                  {t("gameDetail.predictions")}
                </div>
                <div
                  className={
                    activeTab === "analytics"
                      ? "game-detail__tab active"
                      : "game-detail__tab"
                  }
                  onClick={() => handleActiveTab("analytics")}
                >
                  {t("gameDetail.analytics")}
                </div>
              </div>
              {/* </div> */}
              <div
                className="game-details__content"
                ref={additionalContainerRef}
                onScroll={handleDesktopScroll}
              >
                {isTablet && (
                  <div className="game-details__content-section">
                    <GameVote
                      gameAPIDetails={gameAPIDetails}
                      gameDetails={gameDetails}
                    />
                  </div>
                )}
                <div
                  className="game-details__content-section"
                  ref={overviewRef}
                >
                  <div className="game-details__content-title">
                    {t("gameDetail.overView")}
                  </div>
                  <div className="game-details__content-wrapper game-details__overview-wrapper">
                    <GameOverview gameStatictic={gameStatictic} />
                  </div>
                </div>
                <div
                  className="game-details__content-section game-details__chat-section"
                  ref={chatRef}
                >
                  <div className="game-details__content-title">
                    {t("gameDetail.chat")}
                  </div>
                  <div className="game-details__content-wrapper">
                    <div className="comming">Comming soon...</div>
                  </div>
                </div>
                <div
                  className="game-details__content-section"
                  ref={predictionsRef}
                >
                  <div className="game-details__content-title">
                    {t("gameDetail.predictions")}
                  </div>
                  <div className="game-details__content-wrapper">
                    <GamePredictions
                      gameStatictic={gameStatictic}
                      isLoading={isLoading}
                    />
                    <div className="chart-delimeter"></div>
                    <TopGamePredictions
                      data={gameTopPredictions}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
                <div
                  className="game-details__content-section"
                  ref={analyticsRef}
                >
                  <div className="game-details__content-title">
                    {t("gameDetail.analytics")}
                  </div>
                  <div className="game-details__content-wrapper">
                    <HorizontalChart
                      title={t("gameDetail.chartTitle1")}
                      chartData={gameChartInfo.coefChart}
                      isLoading={isLoading}
                    />
                    <div className="chart-delimeter"></div>
                    <HorizontalChart
                      title={t("gameDetail.chartTitle2")}
                      titleIcon="/CAP coin.png"
                      chartData={gameChartInfo.coinsChart}
                      isLoading={isLoading}
                      isCoins={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            {!isSmallLaptop && (
              <div className="game-details__chat">
                <div className="game-details__chat-title">
                  <img src="/gameDetail/chat.svg" alt="chat"></img>
                  {t("gameDetail.chat")}
                </div>
                <div className="comming">Comming soon...</div>
              </div>
            )}
          </div>
          <HomePageFooter />
        </div>
      )}
    </>
  );
};

export default GameDetail;
