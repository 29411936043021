import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";
import { isValidEmail } from "../../../../helpers/validator";
import { eventBus } from "../../../../services/events";
import { CONSTANT } from "../../../../common/constant";
import Model from "../../../molecule/model/Model";

import apiService from "../../../../services/apiService";

import "./GameVote.css";
import { formatNumber } from "../../../../helpers/formater";

const GameVote = ({ gameAPIDetails, gameDetails }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [gameVote, setGameVote] = useState({});
  const [userVote, setUserVote] = useState({});
  const [showLoginModel, setShowLoginModel] = useState(false);

  const [loginEmail, setLoginEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");

  const [error, setError] = useState("form-error-line-hide");
  const [errorClass, setErrorClass] = useState("mb-3");

  useEffect(() => {
    getGameVote();
    getUserVote();
  }, [gameAPIDetails]);

  const handleLoginEmail = (e) => {
    if (!isValidEmail(e.target.value)) {
      setUserName(e.target.value);
    } else {
      setUserEmail(e.target.value);
    }
    setLoginEmail(e.target.value);
    if (e.target.value === "") {
      setErrorClass("mb-3");
      setError("form-error-line-hide");
    }
  };

  const handleEmail = async (e) => {
    e.preventDefault();
    eventBus.emit(CONSTANT.SHOW_LOADER);
    const res = await apiService.getUserName({ email: loginEmail });
    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (res.data) {
      navigate("/login", {
        state: {
          email: loginEmail,
          userName: userName,
          userEmail: userEmail,
          name: res.data.userName,
          profileImgUrl: res?.data.profileImgUrl,
        },
      });
    } else {
      setErrorClass("mb-3 form-error");
      setError("form-error-line-show");
    }
  };

  const getOcRate = () => {
    if (gameAPIDetails?.game_oc_list) {
      const result = gameAPIDetails?.game_oc_list.find(
        (item) => item.group_id === 1
      );

      if (result) {
        return result.oc_list;
      }
    }

    return [{ oc_rate: "-" }, { oc_rate: "-" }, { oc_rate: "-" }];
  };

  const getGameVote = async () => {
    if (gameDetails.game_id) {
      const res = await apiService.getGameVote(gameDetails.game_id);
      if (res) {
        setGameVote(res);
      }
    }
  };

  const getUserVote = async () => {
    if (gameDetails.game_id) {
      const res = await apiService.getUserVote(gameDetails.game_id);

      if (res[0]) {
        setUserVote(res[0]);
      }
    }
  };

  const addGameVote = async (ocName) => {
    if (!localStorage.getItem("token")) {
      setShowLoginModel(true);
      return;
    }

    const data = {
      gameId: gameAPIDetails.game_id,
      ocName: ocName,
    };

    const res = await apiService.addGameVote(data);

    if (res) {
      setGameVote(res);
      setUserVote(res.userVote);
    }
  };

  const renderGameVoteRating = () => {
    return (
      <>
        {gameVote.voteData ? (
          <div className="game-vote_rating-items">
            <div
              className={
                userVote?.ocName === "p1"
                  ? "game-vote_rating-item active item-p1"
                  : "game-vote_rating-item item-p1"
              }
              style={{
                width: gameVote?.voteData?.p1?.percentage
                  ? gameVote?.voteData?.p1?.percentage + "%"
                  : "",
              }}
            >
              <div className="game-vote_progress item-p1"></div>
              <div className="game-vote_progress-title">
                {gameVote?.voteData?.p1?.percentage
                  ? formatNumber(gameVote?.voteData?.p1?.percentage, 1)
                  : "0"}
                % <span>({t("gameDetail.votes.p1")})</span>
              </div>
            </div>
            <div
              className={
                userVote?.ocName === "x"
                  ? "game-vote_rating-item active item-x"
                  : "game-vote_rating-item item-x"
              }
              style={{
                width: gameVote?.voteData?.x?.percentage
                  ? gameVote?.voteData?.x?.percentage + "%"
                  : "",
              }}
            >
              <div className="game-vote_progress item-x"></div>
              <div className="game-vote_progress-title">
                {gameVote?.voteData?.x?.percentage
                  ? formatNumber(gameVote?.voteData?.x?.percentage, 1)
                  : "0"}
                % <span>({t("gameDetail.votes.x")})</span>
              </div>
            </div>
            <div
              className={
                userVote?.ocName === "p2"
                  ? "game-vote_rating-item active item-p2"
                  : "game-vote_rating-item item-p2"
              }
              style={{
                width: gameVote?.voteData?.p2?.percentage
                  ? gameVote?.voteData?.p2?.percentage + "%"
                  : "",
              }}
            >
              <div className="game-vote_progress item-p2"></div>
              <div className="game-vote_progress-title">
                {gameVote?.voteData?.p2?.percentage
                  ? formatNumber(gameVote?.voteData?.p2?.percentage, 1)
                  : "0"}
                % <span>({t("gameDetail.votes.p2")})</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="game-vote__no-votes">
            {t("gameDetail.votes.noVotes")}
          </div>
        )}
      </>
    );
  };

  const renderGameVoteItems = () => {
    return (
      <div className="game-vote_items">
        <div
          className="game-vote_item item-p1"
          onClick={() => addGameVote("p1")}
        >
          <div className="game-vote_item-title">{t("gameDetail.votes.p1")}</div>
          <div className="game-vote_item-value">
            {gameAPIDetails?.game_id ? (
              <>
                {getOcRate()[0]["oc_rate"] !== "-"
                  ? getOcRate()[0]["oc_rate"].toFixed(2)
                  : getOcRate()[0]["oc_rate"]}
              </>
            ) : (
              <div className="skeleton-placeholder-light game-vote_item-value_loader"></div>
            )}
          </div>
        </div>
        <div className="game-vote_item item-x" onClick={() => addGameVote("x")}>
          <div className="game-vote_item-title">{t("gameDetail.votes.x")}</div>
          <div className="game-vote_item-value">
            {gameAPIDetails?.game_id ? (
              <>
                {getOcRate()[1]["oc_rate"] !== "-"
                  ? getOcRate()[1]["oc_rate"].toFixed(2)
                  : getOcRate()[1]["oc_rate"]}
              </>
            ) : (
              <div className="skeleton-placeholder-light game-vote_item-value_loader"></div>
            )}
          </div>
        </div>
        <div
          className="game-vote_item item-p2"
          onClick={() => addGameVote("p2")}
        >
          <div className="game-vote_item-title ">
            {t("gameDetail.votes.p2")}
          </div>
          <div className="game-vote_item-value">
            {gameAPIDetails?.game_id ? (
              <>
                {getOcRate()[2]["oc_rate"] !== "-"
                  ? getOcRate()[2]["oc_rate"].toFixed(2)
                  : getOcRate()[2]["oc_rate"]}
              </>
            ) : (
              <div className="skeleton-placeholder-light game-vote_item-value_loader"></div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="game-vote__wrapper">
      <div className="game-vote__title-wrapper">
        <div className="game-vote__title">{t("gameDetail.votes.title")}</div>
        {userVote?.ocName && (
          <div className="game-vote__user-vote">
            {t("gameDetail.votes.youVote")}
            <span>
              {userVote?.ocName === "p1"
                ? t("gameDetail.votes.p1")
                : userVote?.ocName === "x"
                ? t("gameDetail.votes.x")
                : t("gameDetail.votes.p2")}
            </span>
          </div>
        )}
      </div>
      {userVote?.ocName || gameDetails.gameStatus === "completed"
        ? renderGameVoteRating()
        : renderGameVoteItems()}
      <Model
        isModel={showLoginModel}
        modelClassName={"home-page-signupPage"}
        hideModel={() => setShowLoginModel(false)}
        modelTitle={t("homePageSigninPage.SignIn")}
      >
        <div className="userLoginModel">
          {/* --- signinCapperauditor top --- */}
          <div className="top">
            <div className="btn-containt">
              <button
                type="button"
                className="btn  btn-google-facebook btn-google"
                onClick={() => {
                  window.location.href = process.env.REACT_APP_googleOAuthUrl;
                }}
              >
                <span className="signup-icon">
                  <i className="fab fa-google" />
                </span>
                {t("homePageSigninPage.isGoogleBtn")}
              </button>
              <button
                type="button"
                className="btn btn-google-facebook btn-facebook"
                onClick={() => {
                  window.location.href = process.env.REACT_APP_facebookOAuthUrl;
                }}
              >
                <span className="signup-icon">
                  <i
                    className="fab fa-facebook-f"
                    style={{ color: "#4267B2" }}
                  ></i>
                </span>
                {t("homePageSigninPage.isFabookBtn")}
              </button>
            </div>
            <div className="text-divider">{t("homePageSigninPage.or")}</div>
          </div>
          {/* ------ signincapperauditor form ------  */}
          <div className="bottom">
            <form>
              <div className={errorClass}>
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label form-input-label"
                >
                  {t("homePageSigninPage.emailorusername")}
                </label>
                <input
                  type="email"
                  className="form-control form-input-field"
                  id="InputEmail"
                  defaultValue={""}
                  onChange={handleLoginEmail}
                  placeholder={t("homePageSigninPage.EnteremailPlaceHolder")}
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "#7F87B3",
                  }}
                />
                <p style={{ fontSize: "14px" }} className={error}>
                  {t("SignIn.Youremailorusernameisincorrect")}
                </p>
              </div>
              <div className="mb-4">
                <button
                  className="form-control form-input-field-button"
                  id="Inputbutton"
                  onClick={handleEmail}
                  disabled={loginEmail == ""}
                  style={{
                    pointerEvents: loginEmail == "" ? "none" : "auto",
                  }}
                >
                  {t("homePageSigninPage.Continue")}
                </button>
              </div>
            </form>
            {/* ------- signincapperauditor footer -----  */}
            <div className="signin-footer">
              <span
                className="signincapperauditor-bottom"
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  lineHeight: "20px",
                }}
              >
                {t("homePageSigninPage.subLine1")}{" "}
                <Link
                  to="/signup"
                  style={{
                    color: "#4C5480",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
                >
                  {t("homePageSigninPage.subLine2")}
                </Link>
              </span>
            </div>
          </div>
        </div>
      </Model>
    </div>
  );
};
<></>;

export default GameVote;
